<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addWithdraw'})">عمليات السحب</dashboard-page-title>

    <main-table :fields="fields" list_url="merchant/withdrawals" paginationName="عمليات سحب"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: 'اسم الحساب', key: 'bank_info.account_name', class: 'text-left' },
        { label: 'رقم IBAN', key: 'bank_info.IBAN_number', class: 'text-left' },
        { label: 'Swift Code', key: 'bank_info.swift_code', class: 'text-left' },
        { label: 'اسم الحساب', key: 'bank_info.account_name', class: 'text-left' },
        { label: 'المبلغ', key: 'amount', class: 'text-left' },
        { label: 'المبلغ الحالى', key: 'current_balance', class: 'text-left' },
        { label: 'الحالة', key: 'status', class: 'text-left', type: 'status' },
        { label: 'تاريخ الانشاء', key: 'created_at', class: 'text-left' }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
